import React, { Fragment } from "react"
import styled from "styled-components"
import { lighten } from "polished"

import Layout, { fixContentLinks } from "../components/layouts/Layout"
import {
  Wrapper,
  Flex,
  ButtonLink,
  Color,
  AlignCenter,
  Col,
  Media,
  AlignLeft,
  ButtonA,
} from "../components/utilities"
import { Section, ContactSection } from "../components/elements"

import licensed from "../images/licensed.png"
import upfront from "../images/upfront.png"

const Side1 = styled.div`
  flex: 0 !important;
  flex-basis: 500px !important;
  text-align: left;
`
const Side2 = styled.div`
  flex: 0 !important;
  flex-basis: 500px !important;
  text-align: left;
`

const BannerButton = styled.div`
  background: ${Color.red};
  color: ${Color.white};
  padding: 5px;
  position: relative;
  height: 40px;
  margin-top: 60px;
  margin-bottom: 60px;

  ${ButtonLink} {
    z-index: 1000;
    font-size: 2rem;
    padding: 1.5rem 3rem;
    color: ${Color.grey};
    background: ${Color.white};
    border: 8px solid ${Color.red};
    position: relative;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background: ${lighten(0.1, Color.red)};
      transform: scale(1.2);
      padding: 2rem 3.5rem;
    }
  }

  ${Media.below.tablet`
    height: auto;
    background: transparent;
    margin-bottom: 0;
    margin-top: 20px;

    ${ButtonLink} {
      font-size: 1.5rem;
    }
  `}
`

const BannerGrid = styled.div`
  display: grid;
  grid-template-columns: 250px auto 250px;
  text-align: center;
  align-content: center;
  align-items: center;
  margin-top: -50px;

  ${Media.below.tablet`
    display: block;
    margin-top: 0;

    ${Col} {
      margin-bottom: 1rem;
    }
  `}
`

const Row = styled.div`
  display: flex;

  ${Media.below.tablet`
    flex-wrap: wrap;
  `}
`

const LinkImage = styled.div`
  display: block;
  flex: 20%;
  padding: 0px;
  justify-content: space-between;
  margin-top: 20px;
`

const HomeImage = styled.div`
  height: 250px;
  border: 5px solid ${Color.grey};
  border-bottom: 0;
  background-color: ${Color.red};
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::after {
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${props => props.image && `background-image: url(${props.image});`}
    background-size: cover;
    background-position: center center;
  }

  ${Media.below.tablet`
    height: 150px;
  `}
`

const LinkTitle = styled.div`
  text-align: center;
  color: ${Color.white};
  text-decoration: none;
  font-size: 1.5rem;
  background-color: ${Color.grey};
  padding: 0.8rem;
  transition: all 0.3s ease;
  display: block;

  &:hover {
    text-decoration: none;
  }
`

const DDLink = styled.a`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    ${LinkTitle} {
      background-color: ${Color.red};
    }

    ${HomeImage} {
      border-color: ${Color.red};

      &::after {
        opacity: 0.8;
        left: -10px;
        right: -10px;
        top: -10px;
        bottom: -10px;
      }
    }
  }

  ${Media.below.tablet`
    flex-basis: 50%;
    margin: 0;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    flex-shrink: 0;
  `}
`

const HeaderMeat = styled.div`
  padding-bottom: 4rem;

  ${Media.below.tablet`
    padding: 0;
  `}
`

const RenderHeader = ({ blocks }) => (
  <HeaderMeat>
    <Wrapper>
      <LinkImage>
        <Row>
          {blocks.map(block => (
            <DDLink href={fixContentLinks(block.node.acf.page_url)}>
              <HomeImage image={block.node.featured_media.source_url} />
              <LinkTitle>{block.node.title}</LinkTitle>
            </DDLink>
          ))}
        </Row>
      </LinkImage>
    </Wrapper>
    <BannerButton>
      <Wrapper>
        <BannerGrid>
          <Col>
            <img src={upfront} alt="Upfront Pricing" />
          </Col>
          <Col>
            <ButtonLink to="/contact">Click Here to Request Service</ButtonLink>
          </Col>
          <Col>
            <img src={licensed} alt="Fully Licensed and Insured" />
          </Col>
        </BannerGrid>
      </Wrapper>
    </BannerButton>
  </HeaderMeat>
)

const IndexPage = ({ data, uri, ...props }) => {
  const content = data.allWordpressPage.edges[0].node
  const homeblocks = data.allWordpressWpHomeBlock.edges
  return (
    <Layout
      title={content.acf.seo_title}
      description={content.acf.seo_description}
      uri={uri}
      image={content.featured_media.source_url}
      bodyRender={() =>
        RenderHeader({ blocks: data.allWordpressWpBannerBox.edges })
      }
    >
      {({ wordpressWpConfig }) => (
        <Fragment>
          <Section modifiers={["danger"]}>
            <h1 dangerouslySetInnerHTML={{ __html: content.title }}></h1>
            <Wrapper>
              <Flex>
                <Side1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fixContentLinks(content.content),
                    }}
                  ></div>
                </Side1>
                <Side2>
                  <AlignCenter>
                    <h2>Our Services Include</h2>
                  </AlignCenter>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.acf.our_services,
                    }}
                  ></div>
                  <ButtonLink to="/contact" modifiers={["large", "block"]}>
                    Contact Siferds Today
                  </ButtonLink>
                </Side2>
              </Flex>
            </Wrapper>
          </Section>
          {homeblocks.map((block, ix) => (
            <Section
              key={ix}
              background={
                block.node.featured_media
                  ? block.node.featured_media.source_url
                  : null
              }
              minHeight="450px"
              flex={true}
              modifiers={[
                block.node.acf.theme ? block.node.acf.theme : "primary",
              ]}
              position={block.node.acf.left_align ? "left" : "right"}
            >
              <h2 dangerouslySetInnerHTML={{ __html: block.node.title }}></h2>
              <AlignLeft>
                <div
                  dangerouslySetInnerHTML={{ __html: block.node.content }}
                ></div>
              </AlignLeft>
              {block.node.acf.button_link ? (
                <ButtonA
                  modifiers={[
                    block.node.acf.theme ? block.node.acf.theme : "primary",
                  ]}
                  href={block.node.acf.button_link}
                >
                  {block.node.acf.button_text
                    ? block.node.acf.button_text
                    : "More Information"}
                </ButtonA>
              ) : null}
            </Section>
          ))}
          <ContactSection node={wordpressWpConfig} />
        </Fragment>
      )}
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query IndexQuery {
    allWordpressPage(filter: { path: { eq: "/" } }) {
      edges {
        node {
          title
          content
          featured_media {
            source_url
          }
          acf {
            our_services
            seo_title
            seo_description
          }
        }
      }
    }
    allWordpressWpBannerBox(sort: { fields: acf___order, order: ASC }) {
      edges {
        node {
          title
          acf {
            page_url
          }
          featured_media {
            source_url
          }
        }
      }
    }
    allWordpressWpHomeBlock(sort: { fields: acf___order, order: ASC }) {
      edges {
        node {
          title
          content
          featured_media {
            source_url
          }
          acf {
            theme
            left_align
            button_text
            button_link
          }
        }
      }
    }
  }
`
